import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaHome, FaUsers, FaEnvelopeOpenText, FaUserShield } from 'react-icons/fa';

export default function TheFooter() {
    return (
        <div className='bg-[#1C1C1C] px-4 lg:px-[108px] pt-[40px] pb-[20px]'>
            <div className='text-gray-300 grid grid-cols-1 lg:grid-cols-4 gap-8 lg:gap-16'>
                {/* Logo & Description */}
                <div className='flex flex-col items-start'>
    <Link to="/"><img className='mb-4 w-[150px] lg:w-[200px]' src="./assets/images/footer/footer-logo.png" alt="Logo" /></Link>
    <p className='text-sm lg:text-base mb-4 text-justify'>The Overseas Community Chitral (OCC - Self-Helping) is a group formed to help and support Chitrali people who live in Saudi Arabia. It was previously called the Overseas Chitralis Group Saudi Arabia.</p>
</div>


                {/* Menu Links */}
                <div>
                    <h2 className='font-bold text-lg lg:text-xl mb-4 border-b-2 border-gray-600 pb-2'>Important Links</h2>
                    <ul className='space-y-2'>
                        <li className='flex items-center gap-2'>
                            <FaHome /> <Link to="/" className='hover:underline'>Home</Link>
                        </li>
                        <hr className='border-gray-700' />
                        <li className='flex items-center gap-2'>
                            <FaUsers /> <Link to="/about-us" className='hover:underline'>About Us</Link>
                        </li>
                        <hr className='border-gray-700' />
                        <li className='flex items-center gap-2'>
                            <FaEnvelopeOpenText /> <Link to="/contact-us" className='hover:underline'>Contact Us</Link>
                        </li>
                        <hr className='border-gray-700' />
                        <li className='flex items-center gap-2'>
                            <FaUserShield /> <Link to="/member-verification" className='hover:underline'>Member Verification</Link>
                        </li>
                        <hr className='border-gray-700' />
                        <li className='flex items-center gap-2'>
                            <FaUsers /> <Link to="/become-a-member" className='hover:underline'>Become a Member</Link>
                        </li>
                    </ul>
                </div>

                {/* Contact Information */}
                <div>
                    <h2 className='font-bold text-lg lg:text-xl mb-4 border-b-2 border-gray-600 pb-2'>Contact Us</h2>
                    <ul className='space-y-2'>
                        <li className='flex items-center gap-2'>
                            <FaPhone /> <p>+966 599 235 369</p>
                        </li>
                        <hr className='border-gray-700' />
                        <li className='flex items-center gap-2'>
                            <FaMapMarkerAlt /> <p>Second Floor, Japan Tower, Drosh, Main Office, OCC.</p>
                        </li>
                    </ul>
                </div>

                {/* Social Links */}
                <div>
                    <h2 className='font-bold text-lg lg:text-xl mb-4 border-b-2 border-gray-600 pb-2'>Follow Us</h2>
                    <div className='space-y-2'>
                        <a href="https://www.facebook.com/overseaschitralis.community/" className='flex items-center gap-2 hover:text-gray-400'><FaFacebook /> Facebook</a>
                        <hr className='border-gray-700' />
                        <a href="/" className='flex items-center gap-2 hover:text-gray-400'><FaXTwitter /> Twitter</a>
                        <hr className='border-gray-700' />
                        <a href="/" className='flex items-center gap-2 hover:text-gray-400'><FaInstagram /> Instagram</a>
                        <hr className='border-gray-700' />
                        <a href="/" className='flex items-center gap-2 hover:text-gray-400'><FaYoutube /> YouTube</a>
                    </div>
                </div>
            </div>

            {/* Footer Bottom */}
            <div className='border-t border-gray-700 mt-8 pt-4 text-center'>
                <p className='text-sm text-gray-300'>Copyright © 2024, OCC. Developed by Abu Zar Mishwani.</p>
            </div>
        </div>
    );
}