import React, { useState } from 'react';
import './support-section.css';
import { RiGraduationCapFill } from "react-icons/ri";
import { FaDroplet } from "react-icons/fa6";
import { BiSolidFirstAid } from "react-icons/bi";
import { PiBowlFoodBold } from "react-icons/pi";
import { FaHandshake } from "react-icons/fa6";

export default function SupportSection() {
    const [hoverState, setHoverState] = useState({
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        card5: false
    });

    const handleMouseEnter = (card) => {
        setHoverState(prevState => ({
            ...prevState,
            [card]: true
        }));
    };

    const handleMouseLeave = (card) => {
        setHoverState(prevState => ({
            ...prevState,
            [card]: false
        }));
    };

    return (
        <div className='main_support px-[22px] py-[15px] lg:px-[108px] lg:py-[104px]'>
            <div className='lg:text-2xl xl:text-4xl 2xl:text-4xl text-center mt-4 lg:mb-16 text-white font-extrabold uppercase tracking-wider lg:px-[140px] 2xl:px-[300px] mb-8 md:mb-12'> {/* Added mb-8 for spacing on mobile */}
                Happiness Doesn’t Result From What We Get, But From What We Give
            </div>
            <div className='grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-5'> {/* Use grid layout */}
                {/* Card 1 */}
                <div className='w-full h-[160px] bg-white rounded-lg relative flex items-center justify-center mb-6' // Added mb-6 for vertical spacing
                    onMouseEnter={() => handleMouseEnter('card1')}
                    onMouseLeave={() => handleMouseLeave('card1')}>
                    <div className={` ${hoverState.card1 ? 'scale-150' : ''} absolute bg-theme-black p-3 rounded-full -top-5 duration-300`}>
                        <PiBowlFoodBold className='h-8 w-8 text-white' />
                    </div>
                    <div className='text-theme-black font-bold text-lg px-4 text-center leading-5'>Culture Promotion</div>
                </div>

                {/* Card 2 */}
                <div className='w-full h-[160px] bg-white rounded-lg relative flex items-center justify-center mb-6' // Added mb-6 for vertical spacing
                    onMouseEnter={() => handleMouseEnter('card2')}
                    onMouseLeave={() => handleMouseLeave('card2')}>
                    <div className={` ${hoverState.card2 ? 'scale-150' : ''} absolute bg-theme-black p-3 rounded-full -top-5 duration-300`}>
                        <FaHandshake className='h-8 w-8 text-white' />
                    </div>
                    <div className='text-theme-black font-bold text-lg px-4 text-center leading-5'>Mutual Help</div>
                </div>

                {/* Card 3 */}
                <div className='w-full h-[160px] bg-white rounded-lg relative flex items-center justify-center mb-6' // Added mb-6 for vertical spacing
                    onMouseEnter={() => handleMouseEnter('card3')}
                    onMouseLeave={() => handleMouseLeave('card3')}>
                    <div className={` ${hoverState.card3 ? 'scale-150' : ''} absolute bg-theme-black p-3 rounded-full -top-5 duration-300`}>
                        <RiGraduationCapFill className='h-8 w-8 text-white' />
                    </div>
                    <div className='text-theme-black font-bold text-lg px-4 text-center leading-5'>Education Promotion</div>
                </div>

                {/* Card 4 */}
                <div className='w-full h-[160px] bg-white rounded-lg relative flex items-center justify-center mb-6' // Added mb-6 for vertical spacing
                    onMouseEnter={() => handleMouseEnter('card4')}
                    onMouseLeave={() => handleMouseLeave('card4')}>
                    <div className={` ${hoverState.card4 ? 'scale-150' : ''} absolute bg-theme-black p-3 rounded-full -top-5 duration-300`}>
                        <FaDroplet className='h-8 w-8 text-white' />
                    </div>
                    <div className='text-theme-black font-bold text-lg px-4 text-center leading-5'>Enterprise Support</div>
                </div>

                {/* Card 5 */}
                <div className='w-full h-[160px] bg-white rounded-lg relative flex items-center justify-center mb-6' // Added mb-6 for vertical spacing
                    onMouseEnter={() => handleMouseEnter('card5')}
                    onMouseLeave={() => handleMouseLeave('card5')}>
                    <div className={` ${hoverState.card5 ? 'scale-150' : ''} absolute bg-theme-black p-3 rounded-full -top-5 duration-300`}>
                        <BiSolidFirstAid className='h-8 w-8 text-white' />
                    </div>
                    <div className='text-theme-black font-bold text-lg px-4 text-center leading-5'>Healthcare Support</div>
                </div>
            </div>
        </div>
    );
}