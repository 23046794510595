import React from "react";
import TeamMemberCard from "./TeamMemberCard";

// Team members data
const teamData = {
    Leadership: [
      { name: "Haider Nawaz", role: "Chairman", image: "./assets/images/team-member/haider.png" },
      { name: "Muhammad Aziz", role: "President", image: "./assets/images/team-member/aziz.jpg" },
      { name: "Rustum Wali Shah", role: "Vice President", image: "./assets/images/team-member/rustam.png" },
      { name: "Taj Uddin", role: "Chief Coordinator", image: "./assets/images/team-member/taj.jpeg" },
    ],
    Coordinators: [
      { name: "Hafeez Ullah", role: "Coordinator, Damam", image: "./assets/images/team-member/hafeez.png" },
      { name: "Emran Ali", role: "Coordinator, Damam", image: "./assets/images/team-member/emran.jpeg" },
      { name: "Sher Wazir", role: "Coordinator, Damam", image: "./assets/images/team-member/sher.jpeg" },
      { name: "Nizam Uddin", role: "Coordinator, Al Quoz", image: "./assets/images/team-member/nizam.png" },
      { name: "Amjad Ali", role: "Coordinator, Al Quoz", image: "./assets/images/team-member/amjad.png" },
      { name: "Noor Wali", role: "Coordinator, Al Quoz", image: "./assets/images/team-member/noor.jpeg" },
      { name: "Didar Ishaq", role: "Coordinator, Riyadh", image: "./assets/images/team-member/didar.png" },
      { name: "Dildar Hussain", role: "Coordinator, Riyadh ABV", image: "./assets/images/team-member/dildar.png" },
      { name: "Mumtaz Uddin", role: "Coordinator, Riyadh", image: "./assets/images/team-member/mumtaz.png" },
      { name: "Haider Wali Taj", role: "Coordinator, Riyadh", image: "./assets/images/team-member/haider_wali.png" },
      { name: "Wali Ur Rehman", role: "Coordinator, Riyadh", image: "./assets/images/team-member/wali.png" },
      { name: "Abdul Manan Rechvi", role: "Coordinator, Jeddah", image: "./assets/images/team-member/manan.png" },
      { name: "Muhammad Ishaq", role: "Coordinator, Albaha", image: "./assets/images/team-member/ishaq.png" },
      { name: "Moulana Riaz Uddin", role: "Coordinator, Makkah", image: "./assets/images/team-member/riaz.png" },
      { name: "Ahmad Qadir", role: "Coordinator, Madina", image: "./assets/images/team-member/qadir.png" },
      { name: "Abdul Qayoom", role: "Coordinator, Taif", image: "./assets/images/team-member/qayoom.png" },
      { name: "Qamar Ali", role: "Coordinator, Tabuk", image: "./assets/images/team-member/qamar.png" },
      { name: "Muhammad Jahangir", role: "Coordinator, Abha", image: "./assets/images/team-member/jahangir.png" },
      { name: "Hidayat Ullah", role: "Coordinator, Jezan", image: "./assets/images/team-member/hidayat.png" },
      { name: "Haji Rahmat Uddin", role: "Coordinator, Muzdarifah", image: "./assets/images/team-member/rahmat.jpeg" },
    ],
    Shura: [
      { name: "Qari Muhammad Azam", role: "Shura Member", image: "./assets/images/team-member/azam.png" },
      { name: "Hussain Ali Shah", role: "Shura Member", image: "./assets/images/team-member/hussain.png" },
      { name: "Muhammad Moeen Uddin", role: "Shura Member", image: "./assets/images/team-member/moeen.png" },
      { name: "Siyana Khan", role: "Shura Member", image: "./assets/images/team-member/siyana.png" },
      { name: "Noor Arab", role: "Shura Member", image: "./assets/images/team-member/noor_arab.jpg" },
    ],
    Secretaries: [
      { name: "Abdul Majeed Durrani", role: "General Secretary", image: "./assets/images/team-member/majeed.png" },
      { name: "Hasan Syed", role: "Social Media Secretary", image: "./assets/images/team-member/hasan.png" },
      { name: "Jamil Ur Rehman", role: "Information Secretary", image: "./assets/images/team-member/jamil.png" },
      { name: "Mukhtar Ur Rehman", role: "Development Secretary", image: "./assets/images/team-member/mukhtar.png" },
      { name: "Ali Murad", role: "Jirga Secretary", image: "./assets/images/team-member/murad.png" },
      { name: "Muhammad Sadiq", role: "Office Secretary", image: "./assets/images/team-member/sadiq.png" },
      { name: "Sami Ullah", role: "Office Joint Secretary", image: "./assets/images/team-member/sami.jpeg" },
    ],
    Finance: [
      { name: "Misbah Uddin", role: "Chief Finance", image: "./assets/images/team-member/misbah.png" },
      { name: "Rafi Uddin Khalid", role: "Finance Secretary", image: "./assets/images/team-member/rafi.png" },
      { name: "Abdul Khaliq", role: "Emergency Donation Secretary", image: "./assets/images/team-member/khaliq.png" },
    ],
    Others: [
      { name: "Abu Zar Mishwani", role: "Software Engineer", image: "./assets/images/team-member/abuzar.jpg" },
      { name: "Nazim Wali", role: "Social Media Manager", image: "./assets/images/team-member/nazim.jpeg" },
      { name: "Rafiq Ahmad", role: "Social Media Manager", image: "./assets/images/team-member/rafiq.jpeg" },
      { name: "Haji Ghulam Qadir", role: "Social Media Manager", image: "./assets/images/team-member/ghulam.jpeg" },
      { name: "Mumtaz Ahmad", role: "Cabinet Member", image: "./assets/images/team-member/mumtaz_ahmad.jpeg" },
      { name: "Mujeeb Ullah", role: "WhatsApp Group Admin", image: "./assets/images/team-member/mujeeb.png" },
      { name: "Fakhruddin", role: "Data Entry", image: "./assets/images/team-member/fakhruddin.jpeg" },
      { name: "Noman Ul Haq", role: "Data Entry", image: "./assets/images/team-member/noman.png" },
      { name: "Mir Dad", role: "Data Entry", image: "./assets/images/team-member/mir.jpeg" },
      { name: "Shujaat Ali", role: "Data Entry", image: "./assets/images/team-member/shujaat.jpeg" },
    ],
  };

  function SectionHeading({ title }) {
    return (
      <div className="relative w-full py-4 mt-4 mb-4">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <h3 className="relative bg-white text-black text-2xl font-semibold px-4 text-center mx-auto w-max shadow-lg rounded-md">
          {title}
        </h3>
      </div>
    );
}
  
  

export default function OurTeamMembers() {
  return (
    <div className="py-10 px-6 lg:px-16">
      <header className="text-center mb-8">
        <h2 className="text-3xl lg:text-4xl font-bold">Meet Our Team</h2>
        <p className="text-gray-700 text-sm lg:text-base">
          Dedicated individuals making a difference.
        </p>
      </header>

      {Object.entries(teamData).map(([category, members], index) => (
        <div key={index} className="mb-8">
          <SectionHeading title={category} />
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
            {members.map((member, idx) => (
              <TeamMemberCard
                key={idx}
                name={member.name}
                role={member.role}
                image={member.image}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
