import React, { useEffect, useState } from 'react';
import './help-section.css';
import HelpCard from './HelpCard';

export default function TheHelpSection() {
    const [cardData, setCardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("/help-section.json");
                const data = await response.json();
                setCardData(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='main_help px-4 py-6 lg:px-20 lg:py-16'>
            <div className='text-2xl lg:text-4xl 2xl:text-[50px] text-center mt-4 mb-8 text-white font-extrabold uppercase tracking-wider'>
                How Can We Help You?
            </div>
            <div className='grid-container'>
                {cardData.map((item) => (
                    <HelpCard key={item._id} item={item} />
                ))}
            </div>
        </div>
    );
}
