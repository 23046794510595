import React from 'react'

export default function CommunitySupportSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col'>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-3.png" alt="" />
                </div>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px] space-y-4 lg:space-y-4 2xl:space-y-6'>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_heading'>Community Support</div>
                    <div className='text-[#230E0E] opacity-90 text-justify w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <ul className='list-disc pl-5'>
                            <li><strong>Support for Death of a Member:</strong> A contribution of 100 Riyals is collected from members to aid the family of a deceased member. The family receives 30,000 Riyals, distributed according to Islamic inheritance rules.</li>
                            <li><strong>Help for Serious Accidents:</strong> For serious injuries, such as head trauma or broken bones, 5,000 Riyals is provided for medical expenses. In case of death after an accident, treatment costs are subtracted from the death support fund.</li>
                            <li><strong>Assistance for Deportation:</strong> If a member is deported within the first year of joining, they receive 500-1,000 Riyals, depending on dues. Members with over a year of membership are supported based on duration.</li>
                            <li><strong>Monthly Support for Disabled Members:</strong> 500 Riyals per month for up to five years, along with potential visa and flight support for one child.</li>
                            <li><strong>Exit Benefits:</strong> Members leaving after 1 year receive 2,000 Riyals, and up to 15,000 Riyals after 5 years, depending on membership duration.</li>
                            <li><strong>Help for Expired Visa Holders:</strong> Support provided in the same manner as active members.</li>
                            <li><strong>Emergency Travel Help:</strong> Members can get a flight to Pakistan with a guarantor committed to repayment. If unpaid, the guarantor is responsible for reimbursing the community.</li>
                            <li><strong>Membership Rules and Fees:</strong> A one-time registration fee of 50 Riyals and an annual fee of 200 Riyals apply to support operations and services.</li>
                            <li><strong>Refund Policy:</strong> No refunds within the first three years. After three years, members may be eligible for a 50-80% refund, based on council approval.</li>
                            <li><strong>Amendment of Rules:</strong> Changes can be made by the Chairman and President with the council’s input, typically semi-annually or annually.</li>
                        </ul>
                    </div>
                    <div className='flex justify-center'>
                    <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white lg:text-sm xl:text-base 2xl:text-lg px-[20px] py-[10px] lg:px-[18px] lg:py-[8px] xl:px-[28px] xl:py-[12px] rounded-lg tracking-wide shadow-md transform hover:scale-105'>
                            Join Now
                        </button>                    </div>                
                </div>
            </div>
        </>
    )
}
