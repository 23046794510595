import React from 'react';
import { Link } from 'react-router-dom';

export default function TheCabinet() {
    return (
        <>
            <section className='px-[22px] pt-[60px] lg:px-[108px] lg:pt-[100px] flex flex-col lg:flex-row justify-between items-end'>
                <div className='w-full lg:w-[485px] xl:w-[525px] 2xl:w-[785px] space-y-3 lg:space-y-4 2xl:space-y-6'>
                    <h1 className='text-theme-black font-extrabold tracking-wide text-xs lg:text-base 2xl:text-2xl'>OCC Cabinet</h1>
                    <p className='text-justify text-stone-900 text-opacity-80 text-xs lg:text-sm xl:text-base 2xl:text-2xl'>
                        The OCC Cabinet consists of dedicated leaders who guide and support our community. They make key decisions, implement policies, and ensure the well-being of all members. Meet the team driving our mission forward.
                    </p>
                </div>
                <div className='mt-4 lg:mt-0'> {/* Added top margin for mobile */}
                    <Link to="/our-team">
                        <div className='px-4 py-2 lg:px-6 lg:py-2 bg-black text-sm lg:text-base text-white rounded-lg cursor-pointer'>
                            View All Team →
                        </div>
                    </Link>
                </div>
            </section>

            <section className='px-[22px] py-[15px] lg:px-[108px] lg:py-[40px] flex justify-between lg:flex-nowrap flex-wrap mt-4 lg:mt-0 mb-9 lg:mb-0'>
                {[
                    { name: 'Haider Nawaz', title: 'Chairman', imageSrc: './assets/images/team-member/haider.png' },
                    { name: 'Muhammad Aziz', title: 'President', imageSrc: './assets/images/team-member/aziz.jpg' },
                    { name: 'Rustum Wali Shah', title: 'Vice President', imageSrc: './assets/images/team-member/rustam.png' },
                    { name: 'Taj Uddin', title: 'Chief Coordinator', imageSrc: './assets/images/team-member/taj.jpeg' }
                ].map((member, index) => (
                    <div key={index} className='text-center'>
                        <img className='mt-4 lg:mt-0 rounded cabinet_image_shadow w-[149px] h-[153px] lg:w-[166px] lg:h-[182px] xl:w-[216px] xl:h-[222px] 2xl:w-[296px] 2xl:h-[288px]' 
                             src={member.imageSrc} 
                             alt={`Image of ${member.name}`} />
                        <h4 className='mt-3 lg:mt-4 2xl:mt-6 lg:text-base 2xl:text-2xl font-extrabold text-theme-black'>{member.name}</h4> {/* Updated to font-extrabold */}
                        <p className='lg:text-sm 2xl:text-xl text-xs'>{member.title}</p>
                    </div>
                ))}
            </section>
        </>
    );
}
