import React from 'react';

export default function OurAimSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col-reverse'>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px] space-y-4'>
                    <div className='font-bold text-theme-black tracking-wide lg:text-lg xl:text-xl custom_about_heading mt-4 lg:mt-0'>Our Mission</div>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_sub_heading'>Supporting and Strengthening Our Community</div>
                    <div className='text-[#230E0E] opacity-90 text-justify w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <p>Our mission is to make sure every Chitrali person in Saudi Arabia feels supported and secure. We aim to create a community that stands by each other in difficult times and shares resources for everyone’s benefit.</p>
                        <ul className='list-disc pl-5'>
                            <li><b>Helping in Times of Need</b>: We provide financial support, medical assistance, and help in emergencies.</li>
                            <li><b>Building a Safe Space</b>: We want members to feel safe and valued.</li>
                            <li><b>Strengthening Bonds</b>: Our community is built on trust and mutual respect.</li>
                        </ul>
                    </div>

                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_sub_heading'>A Future of Unity and Care</div>
                    <div className='text-[#230E0E] opacity-90 text-justify w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <p>Our vision is to create a community where Chitrali people can live with peace of mind and know that help is always there when needed. We hope for a future where every member feels a sense of belonging and togetherness.</p>
                        <ul className='list-disc pl-5'>
                            <li><b>A Place of Unity</b>: We want to bring everyone together as one strong family.</li>
                            <li><b>Support for All</b>: We aim to make sure no one has to face challenges alone.</li>
                            <li><b>A Culture of Care</b>: Our vision is to create an environment where people care for and uplift each other.</li>
                        </ul>
                    </div>
                </div>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-2.png" alt="Community Image" />
                </div>
            </div>
        </>
    );
}
