import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function OurVisionSection() {
    const [rotationState, setRotationState] = useState(false);

    const handleMouseEnter = () => {
        setRotationState(true);
    };

    const handleMouseLeave = () => {
        setRotationState(false);
    };

    return (
        <>
            <div className='px-[22px] py-[15px] lg:px-[108px] lg:py-[104px] flex flex-col-reverse lg:flex-row justify-between'>

                {/* Vision Text Section */}
                <div className='lg:w-[500px] xl:w-[620px] 2xl:w-[900px] space-y-3 lg:space-y-4 2xl:space-y-6 mt-8 lg:mt-0'>
                    
                    {/* Vision Heading */}
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl 2xl:text-4xl font-black lg:w-[85%] 2xl:w-[75%]'>
                        <h2>OUR VISION</h2>
                    </div>
                    
                    {/* Vision Description */}
                    <p className='text-[#230E0E] opacity-90 text-justify lg:text-sm xl:text-base 2xl:text-2xl lg:w-[95%] 2xl:w-[90%]'>
                    Our vision is to build a strong, supportive community where Chitrali people in Saudi Arabia can come together, help each other, and feel secure. We strive to create a place where members have access to financial aid, medical support, and other assistance when needed. Our goal is to ensure everyone feels connected, valued, and cared for, creating an environment where our community can grow and prosper together.
                    </p>
                    
                    {/* Learn More Button */}
                    <div className='mt-6'>
                        <Link to="/about-us">
                            <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white px-[26px] py-[10px] lg:px-[21px] lg:py-[8px] xl:px-[31px] xl:py-[13px] 2xl:px-[38px] 2xl:py-[17px] lg:text-base 2xl:text-xl rounded-lg tracking-wide'>
                                <h6>Learn More →</h6>
                            </button>
                        </Link>
                    </div>
                </div>

{/* Chairman Section */}
<div 
    className='text-center relative cursor-pointer hidden lg:block' 
    onMouseEnter={handleMouseEnter} 
    onMouseLeave={handleMouseLeave}
>
    {/* Rotating Circle */}
    <div className='lg:w-[200px] lg:h-[200px] xl:w-[280px] xl:h-[280px] 2xl:w-[360px] 2xl:h-[360px]'>
        <img 
            className={`${rotationState ? 'rotate-90' : ''} duration-300`} 
            src="./assets/images/home-page/circle-border.png" 
            alt="" 
        />
    </div>
    
    {/* Chairman Image */}
    <div className='absolute xl:top-4 xl:left-5 lg:top-4 lg:left-4 top-0'> 
        <img 
            className='2xl:w-[258px] 2xl:h-[258px] xl:w-[245px] xl:h-[245px] lg:w-[170px] lg:h-[170px] rounded-full' 
            src="./assets/images/home-page/our-vision/haider-cm.png" 
            alt="" 
        />
            <div className='mt-10 text-center'> {/* Reduced margin-top for closer positioning */}
        <div className='font-bold text-theme-black lg:text-lg 2xl:text-4xl'><h2>Haider Nawaz</h2></div>
        <div className='text-theme-black lg:text-base 2xl:text-2xl'><h4>Chairman</h4></div>
    </div>
    </div>

</div>

                {/* Mobile View Image */}
                <div className='block lg:hidden mx-auto mt-16'>
                    <img src="./assets/images/home-page/mobile-circle-img.png" alt="" />
                </div>

            </div>
        </>
    );
}