import React from "react";

const TeamMemberCard = ({ name, role, image }) => (
  <div className="text-center mx-auto">
    {/* Wrapper for square aspect ratio */}
    <div className="w-[150px] lg:w-[200px] xl:w-[250px] aspect-square mx-auto overflow-hidden rounded-lg shadow-lg">
      <img
        className="w-full h-full object-cover"
        src={image}
        alt={name}
      />
    </div>
    {/* Name and role */}
    <h4 className="mt-3 font-bold text-lg">{name}</h4>
    <p className="text-sm text-gray-600">{role}</p>
  </div>
);

export default TeamMemberCard;
