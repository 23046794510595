import React from 'react';
import { Link } from 'react-router-dom';

export default function HelpCard({ item }) {
    return (
        <div className='w-[160px] h-[260px] lg:w-[200px] lg:h-[300px] xl:w-[300px] xl:h-[320px] bg-white rounded-md text-center p-4 mt-5 flex flex-col justify-between'>
            <div>
                <img className='mx-auto w-[50px] h-[50px] lg:w-[60px] lg:h-[60px]' src={item.icon} alt="" />
            </div>
            <div className='mt-4 font-bold text-theme-black tracking-wide text-lg'>{item.name}</div>
            <div className='mt-2 text-stone-900 text-opacity-70 text-sm'>{item.description}</div>
            <Link to={item.linkPage}>
                <button className='mt-4 bg-theme-black text-white rounded-lg py-2 px-4 hover:bg-white hover:text-black border border-transparent hover:border-black transition duration-300 cursor-pointer'>
                    {item.buttonText}
                </button>
            </Link>
        </div>
    );
}
