import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

export default function TheLogin({ setFileName }) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [fileName, setFileNameState] = useState('');


    const navigate = useNavigate();

    // input field state
    const [showPassword, setShowPassword] = useState(false);
    const [saudiNumber, setSaudiNumber] = useState('');
    const [password, setPassword] = useState('');

    // error state
    const [numberError, setNumberError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    console.log(isLoggedIn)
    console.log(fileName)
    console.log(setPasswordError)
    console.log(setFileNameState)

    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault()

        // console.log(cnic, password)
        setSaudiNumber(saudiNumber)
        setPassword(password)



        try {

            if (saudiNumber === '') {
                toast.error("Please Fill all Fields")
                setNumberError(true)
            }
            else if (password === '') {
                toast.error("Please Fill Password")
                setPasswordError(true)
            }

            else if (saudiNumber.length < 10) {
                numberError(true)
                toast.error("Please Fill all Fields")
            }
            else {


                const data = {

                    saudiNumber: saudiNumber,
                    password: password
                }

                const response = await axios.post("https://dashboard.overseaschitraliksa.org/api/login", data);

                if (response.status === 200) {
                    // console.log(response);


                    toast.success('Login Success!');


                    // Assume response.data.token contains the received token
                    const token = response.data.token;

                    // Store the token securely (for example, in localStorage)
                    localStorage.setItem('authToken', token);


                    navigate('/')

                    const fileNameFromResponse = response.data.data.fileName;
                    setFileName(fileNameFromResponse);
                    setIsLoggedIn(true);

                    // Store user's fileName in localStorage
                    localStorage.setItem('isLoggedIn', true);
                    localStorage.setItem('fileName', fileNameFromResponse);

                    // console.log("toekn from login", token)
                }

            }

        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message);
        }
    }

    return (
        <>

            <div className=' login-page-hero px-[22px] flex lg:hidden items-center'>
                <div className='text-white font-bold text-[30px] mt-12'>Login</div>
            </div>
            <div className='flex items-center justify-between '>
                <div className='px-[22px] lg:pl-[128px] 2xl:flex-initial 2xl:w-[50rem]'>
                    <div className='text-theme-black font-bold text-[20px] lg:text-[30px] 2xl:text-[50px] mt-10 lg:mt-12 2xl:mt-20'>Welcome Back</div>
                    <div className='mt-3 text-base lg:text-md 2xl:text-xl text-theme-black'>Enter you Number and Password to Access your Account</div>

                    {/* login form */}
                    <div className='mt-12'>
                        <label className="form-control">
                            <div className="label">
                                <span className="font-bold text-black label-text 2xl:text-lg">Saudi Number</span>
                            </div>
                            <input type="number" placeholder="+966 000 0000"
                                className="input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] py-4  2xl:input-lg"
                                value={saudiNumber}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setSaudiNumber(value);
                                    if (value.length < 10) {
                                        setNumberError(true);
                                    } else {
                                        setNumberError(false);
                                    }
                                }} />
                            <div className='h-2'>
                                {numberError && (
                                    <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter 10 digits atleast</p>
                                )}
                            </div>
                        </label>

                        <label className="relative mt-4 form-control">
                            <div className="label">
                                <span className="font-bold text-black label-text 2xl:text-lg">Password</span>
                            </div>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Enter your password"
                                className="input input-bordered border focus:outline-none border-white focus:border-black  bg-[#F9FAFB] py-4 2xl:input-lg"
                                value={password}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    setPassword(value);
                                }}
                            />

                            {!showPassword ? (
                                <svg
                                    className='absolute top-[50px] right-3 lg:top-[53px] lg:right-3 2xl:top-[69px] cursor-pointer'
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                    onClick={togglePasswordVisibility}
                                >
                                    <path
                                        d="M8 4C3 4 1 8.5 1 8.5C1 8.5 3 13 8 13C13 13 15 8.5 15 8.5C15 8.5 13 4 8 4Z"
                                        stroke="#344153"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z"
                                        stroke="#344153"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    {/* Extra Diagonal Line */}
                                    <line
                                        x1="3"
                                        y1="3"
                                        x2="13"
                                        y2="13"
                                        stroke="#344153"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                            )
                                :
                                (
                                    <svg
                                        className='absolute top-[50px] right-3 lg:top-[53px] lg:right-3 2xl:top-[69px] cursor-pointer'
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <path
                                            d="M8 4C3 4 1 8.5 1 8.5C1 8.5 3 13 8 13C13 13 15 8.5 15 8.5C15 8.5 13 4 8 4Z"
                                            stroke="#344153"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M8 11C9.38071 11 10.5 9.88071 10.5 8.5C10.5 7.11929 9.38071 6 8 6C6.61929 6 5.5 7.11929 5.5 8.5C5.5 9.88071 6.61929 11 8 11Z"
                                            stroke="#344153"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                )
                            }
                            <div className='h-2'>
                                {passwordError && (
                                    <p class="mt-2 text-xs text-red-600 dark:text-red-500">Please enter Password</p>
                                )}
                            </div>
                        </label>


                        <div className='flex justify-between mt-3 lg:mt-2 2xl:mt-6'>
                            <div className='flex items-center gap-1 text-sm'>
                                <input id='checkbox' type="checkbox" className="rounded checkbox-xs lg:checkbox-xs 2xl:checkbox-md lg:rounded-md ms-1 lg:ms-0" />
                                <label for='checkbox' className='font-bold text-[#344153] tracking-wider 2xl:text-lg mt-1'> Remember me</label>
                            </div>
                            <Link to="https://dashboard.overseaschitraliksa.org/password.request">
                                <div className='mt-2 text-xs font-bold underline underline-offset-4 2xl:text-lg'>
                                    Forgot Password?
                                </div>
                            </Link>
                        </div>

                        <div onClick={handleSubmit} className='bg-theme-black text-white text-center py-[14px]  lg:py-[14px] 2xl:py-[21px] 2xl:text-2xl mt-6 rounded-md cursor-pointer'>Login</div>
                        <div className='text-[#8A8A8A] mb-12 lg:mb-0 text-xs lg:text-sm 2xl:text-2xl text-right mt-4 font-bold'>Don’t have an account?
                            <Link to='/become-a-member'><span className='underline text-theme-black lg:text-lg 2xl:text-2xl'> Register Now</span></Link>
                        </div>
                    </div>
                </div>
                <div>
                    <img className='hidden lg:block lg:h-[500px] lg:w-[540px] 2xl:h-[700px] 2xl:w-[740px] lg:mt-[88px] 2xl:mt-[130px]' src="./assets/images/login-right-pic.png" alt="" />
                </div>


            </div>
        </>
    )
}
