import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaUsers, FaEnvelope, FaUserCheck, FaUserPlus } from 'react-icons/fa';

export default function TheNavbar({ fileName }) {
    const location = useLocation();
    const [isSticky, setSticky] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setSticky(window.scrollY > 0);
        };
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        const closeMobileMenu = () => setMobileMenuOpen(false);

        const handleOutsideClick = (event) => {
            if (isMobileMenuOpen && !event.target.closest('.drawer')) {
                closeMobileMenu();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    const toggleMobileMenu = () => setMobileMenuOpen(!isMobileMenuOpen);
    const handleLinkClick = () => setMobileMenuOpen(false);

    return (
        <>
            {/* Desktop Navbar */}
            <div className="fixed top-0 left-0 right-0 z-40">
                <div className={`hidden lg:flex justify-between items-center lg:px-[108px] lg:py-[16px] bg-white backdrop-blur-[10px] duration-300 ${isSticky ? 'bg-opacity-100' : 'bg-opacity-30'}`}>
                    <Link to="/">
                        <img className="lg:h-[52px] lg:w-[256px]" src="./assets/images/navbar/logo.png" alt="Logo" />
                    </Link>
                    <div className="flex items-center gap-6">
                        <Link to="/">
                            <div className="relative flex items-center gap-2 text-black-theme lg:text-sm xl:text-base 2xl:text-lg">
                                <FaHome size={20} />
                                <a href="/" className={`text-black-theme ${location.pathname === '/' ? 'font-bold' : ''}`}>Home</a>
                            </div>
                        </Link>
                        <Link to="/about-us">
                            <div className="relative flex items-center gap-2 text-black-theme lg:text-sm xl:text-base 2xl:text-lg">
                                <FaUsers size={20} />
                                <a href="/about-us" className={`text-black-theme ${location.pathname === '/about-us' ? 'font-bold' : ''}`}>About</a>
                            </div>
                        </Link>
                        <Link to="/our-team">
                            <div className="relative flex items-center gap-2 text-black-theme lg:text-sm xl:text-base 2xl:text-lg">
                                <FaUsers size={20} />
                                <a href="/our-team" className={`text-black-theme ${location.pathname === '/our-team' ? 'font-bold' : ''}`}>Our Team</a>
                            </div>
                        </Link>
                        <Link to="/contact-us">
                            <div className="relative flex items-center gap-2 text-black-theme lg:text-sm xl:text-base 2xl:text-lg">
                                <FaEnvelope size={20} />
                                <a href="/contact-us" className={`text-black-theme ${location.pathname === '/contact-us' ? 'font-bold' : ''}`}>Contact</a>
                            </div>
                        </Link>
                        <Link to="/member-verification">
                            <div className="relative flex items-center gap-2 text-black-theme lg:text-sm xl:text-base 2xl:text-lg">
                                <FaUserCheck size={20} />
                                <a href="/member-verification" className={`text-black-theme ${location.pathname === '/member-verification' ? 'font-bold' : ''}`}>Member Verification</a>
                            </div>
                        </Link>

                        {fileName ? (
                            <Link to="https://dashboard.overseaschitraliksa.com">
                                <label className="drawer-button btn btn-ghost btn-circle avatar">
                                    <div className="w-10 rounded-full">
                                        <img alt="User Avatar" src={fileName} />
                                    </div>
                                </label>
                            </Link>
                        ) : (
                            <Link to="/become-a-member">
                                <div className="flex items-center gap-2 bg-theme-black text-white lg:text-sm xl:text-base 2xl:text-lg xl:px-[26px] xl:py-[13px] lg:px-[8px] lg:py-[14px] rounded-lg cursor-pointer">
                                    <FaUserPlus size={20} />
                                    Become a Member
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            {/* Mobile Navbar */}
            <div className="flex lg:hidden">
    <div className="drawer" style={{ zIndex: 9999 }}>
        <input id="my-drawer" type="checkbox" className="drawer-toggle" checked={isMobileMenuOpen} readOnly />
        <div className="drawer-content">
            <div className={`flex justify-between px-[30px] py-[15px] duration-300 ${isSticky ? 'bg-white bg-opacity-100' : 'bg-white backdrop-blur-[10px] bg-opacity-30'}`}>
                <img src="./assets/images/navbar/mobile-black-logo.png" alt="Mobile Logo" />
                <label htmlFor="my-drawer" className="drawer-button" onClick={toggleMobileMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={`h-8 w-8 ${isSticky ? 'text-black' : 'text-black'}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </label>
            </div>
        </div>
        <div className="drawer-side w-80">
            <ul className="min-h-full p-0 bg-white menu pt-7 w-80">
                <div className="flex items-center justify-between px-6 mb-6">
                    <img src="./assets/images/navbar/mobile-white-logo.png" alt="Mobile Logo White" />
                    <img onClick={handleLinkClick} src="./assets/images/navbar/drawer-x.png" alt="Close Drawer" />
                </div>
                <Link to="/" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaHome size={20} />
                    <a href="/" className={`text-black-theme ${location.pathname === '/' ? 'font-bold' : ''}`}>Home</a>
                </Link>
                <Link to="/about-us" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/about-us' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaUsers size={20} />
                    <a href="/about-us" className={`text-black-theme ${location.pathname === '/about-us' ? 'font-bold' : ''}`}>About Us</a>
                </Link>
                {/* New Our Team Link */}
                <Link to="/our-team" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/our-team' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaUsers size={20} />
                    <a href="/our-team" className={`text-black-theme ${location.pathname === '/our-team' ? 'font-bold' : ''}`}>Our Team</a>
                </Link>
                {/* Existing Links */}
                <Link to="/contact-us" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/contact-us' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaEnvelope size={20} />
                    <a href="/contact-us" className={`text-black-theme ${location.pathname === '/contact-us' ? 'font-bold' : ''}`}>Contact Us</a>
                </Link>
                <Link to="/member-verification" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/member-verification' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaUserCheck size={20} />
                    <a href="/member-verification" className={`text-black-theme ${location.pathname === '/member-verification' ? 'font-bold' : ''}`}>Member Verification</a>
                </Link>
                {/* Become a Member Link */}
                <Link to="/become-a-member" onClick={handleLinkClick} className={`ps-10 py-3 flex items-center gap-2 ${location.pathname === '/become-a-member' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                    <FaUserPlus size={20} />
                    Become a Member
                </Link>
            </ul>
        </div>
    </div>
</div>
        </>
    );
}