import React from 'react';
import './about-us.css';

export default function AboutUsHero() {
    return (
        <>
            <div className='about_hero px-[22px] lg:px-[108px] flex items-center'>
                <h1 className='text-white font-bold text-[30px] lg:text-[60px]'>ABOUT US</h1>
            </div>
        </>
    );
}