import React from 'react';
import './HeroSection.css';

export default function HeroSection() {
    return (
        <>
            <div className="main_hero flex flex-col lg:flex-row justify-between items-center px-6 lg:px-28 py-8 lg:py-24">
                {/* Left Section */}
                <div className="space-y-6 mt-12 lg:mt-0">
                    <div className="helping-text"> {/* New class for custom styling */}
                        <h6>A Self-Helping</h6>
                    </div>
                    <h1 className="text-white text-3xl lg:text-4xl xl:text-6xl font-bold tracking-wide w-4/5 leading-snug">
                        <h1>OVERSEAS CHITRALIS COMMUNITY</h1>
                    </h1>
                    <p className="text-white text-sm lg:text-lg xl:text-xl w-4/5 lg:w-3/5">
                    <div className="helping-text-desc"> {/* New class for custom styling */}
                    <p>We are a supportive community for Chitrali people in Saudi Arabia, offering financial aid, medical assistance, and more to ensure a safe and caring environment.</p>
                   </div>
                    </p>

                </div>

                {/* Right Section */}

            </div>
        </>
    );
}