import React from 'react';

export default function AboutSection() {
    return (
        <>
            <div className='px-[22px] py-[60px] lg:px-[108px] lg:py-[90px] flex justify-between items-center lg:flex-row flex-col'>
                <div className='lg:w-[520px] xl:w-[520px] 2xl:w-[720px] lg:pr-10 xl:pr-4 2xl:pr-0'>
                    <img className='custom_about_img' src="./assets/images/about-us-page/about-blob-1.png" alt="Community Image" />
                </div>
                <div className='lg:w-[530px] xl:w-[530px] 2xl:w-[720px] space-y-4'>
                    <div className='font-bold text-theme-black tracking-wide lg:text-lg xl:text-xl custom_about_heading mt-4 lg:mt-0'>Our History</div>
                    <div className='text-theme-black text-lg lg:text-2xl xl:text-3xl font-black custom_about_sub_heading'>Who We Are?</div>
                    <div className='text-[#230E0E] opacity-90 text-justify xl:w-[94%] lg:w-[94%] flex flex-col gap-4 lg:text-xs xl:text-sm custom_about_para'>
                        <p>
                            OCC (Overseas Community Chitral) was created to bring Chitrali people in Saudi Arabia together and build a strong support network. Our journey started with the aim of connecting individuals from Chitral, preserving our culture, and helping each other in times of need. Over time, we have grown into a well-established community that values support, friendship, and shared goals.
                        </p>
                        <ul className='list-disc pl-5'>
                            <li><b>Established to Connect</b>: We wanted to connect Chitrali people living far from home.</li>
                            <li><b>A Community Built on Trust</b>: We have built a place where members can trust each other and feel safe.</li>
                            <li><b>Growing Stronger Together</b>: Over the years, we have expanded our reach and impact, making sure we support everyone who needs us.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}