import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/fa';

export default function MemberVerificationForm() {
    const [iqamaNumber, setIqamaNumber] = useState("");
    const [verifiedData, setVerifiedData] = useState({});
    const [isUser, setIsUser] = useState('');
    const [userVerified, setUserVerified] = useState(false);

    const handleIqamaVerify = async (e) => {
        e.preventDefault();

        const data = {
            iqamaNumber: iqamaNumber,
        };

        try {
            if (iqamaNumber.length < 10) {
                toast.error("Enter Correct Iqama Number!");
            } else {
                const response = await axios.post("https://dashboard.overseaschitraliksa.org/api/searchByIqamaNumber", data);

                if (response.status === 200) {
                    toast.success(response.data.message);
                    setVerifiedData(response.data.user);
                    setUserVerified(true);
                    setIsUser(response.data.message);
                } else if (response.status === 404) {
                    // Handle not found
                }
            }
        } catch (error) {
            toast.error(error.response.data.message);
            setIsUser(error.response.data.message);
        }
    };

    return (
        <>
            <div className='py-[60px] px-[22px] lg:px-[108px] lg:py-[100px]'>
                <h1 className='font-bold tracking-wide text-base lg:text-lg 2xl:text-2xl'>Member Verification</h1>
                <p className='font-bold text-[25px] lg:text-[30px] xl:text-[40px] 2xl:text-[50px] tracking-wide mt-2 lg:mt-3'>Does This Member Exist?</p>

                <div className='flex flex-col lg:flex-row mt-12 items-start justify-between'>
                    <div className='flex items-center gap-4 mb-6 lg:mb-0 w-full lg:w-auto'>
                        <div className='w-full lg:w-[30rem] xl:w-[36rem] 2xl:w-[40rem]'>
                            <label className="form-control relative">
                                <div className="label">
                                    <span className="label-text font-bold text-black lg:text-sm 2xl:text-lg">Iqama Number</span>
                                </div>
                                <input
                                    type="text"
                                    placeholder="0000000000"
                                    className="input input-bordered border focus:outline-none border-white focus:border-black bg-[#F9FAFB] px-4 py-2 w-full lg:px-8 lg:py-2 lg:w-full lg:text-base"
                                    value={iqamaNumber}
                                    onChange={(e) => setIqamaNumber(e.target.value)}
                                />
                                <div className='h-2'>
                                    <p className="mt-2 text-xs text-red-600 dark:text-red-500">{isUser}</p>
                                </div>
                            </label>
                        </div>
                        <div className='w-full lg:w-auto'>
                            <button
                                onClick={handleIqamaVerify}
                                className='w-full lg:w-auto bg-theme-black text-white px-4 py-2 lg:px-6 lg:py-2 xl:px-10 xl:py-2 2xl:px-12 2xl:py-3 lg:text-base 2xl:text-xl rounded-lg mt-8 border border-theme-black hover:bg-white hover:text-theme-black duration-150'
                            >
                                Verify
                            </button>
                        </div>
                    </div>

                    {/* Verification card */}
                    {userVerified && (
                        <div className='w-full lg:w-[346px] h-[305px] lg:h-[390px] lg:w-[400px] xl:h-[400px] xl:w-[500px] 2xl:h-[500px] 2xl:w-[700px] bg-[#F9FAFB] rounded-lg px-[20px] py-[17px] lg:px-[40px] lg:py-[30px] mt-8 lg:mt-10 desktop-margin-left'>
                            <div className='flex items-center gap-5'>
                                <div>
                                    <img className='w-[74px] h-[74px] lg:h-[108px] lg:w-[108px] rounded-full' src={`${verifiedData.fileName ? `${verifiedData.fileName}` : './assets/images/member-verification/member-verify-pic.png'}`} alt="Member Picture" />
                                </div>
                                <div>
                                    <h3 className='font-bold text-theme-black text-sm lg:text-base 2xl:text-xl'>
                                        {verifiedData.name ? `${verifiedData.name}` : 'Hamza Khan'}
                                        {verifiedData.name && (
                                            <FaCheckCircle className='inline-block ml-2 text-blue-500' />
                                        )}
                                    </h3>
                                    <p className='text-theme-black text-sm lg:text-base 2xl:text-xl'>
                                        <strong className='pr-2'>Iqama No:</strong> {verifiedData.iqamaNumber ? `${verifiedData.iqamaNumber}` : '000000'}
                                    </p>
                                </div>
                            </div>

                            <div className='flex justify-between mt-[30px] lg:mt-[50px] 2xl:mt-[60px] text-sm lg:text-base 2xl:text-xl text-theme-black'>
                                <h4 className='font-bold'>Father Name</h4>
                                <p>{verifiedData.fatherName ? `${verifiedData.fatherName}` : 'Javid Iqbal'}</p>
                            </div>
                            <div className='flex justify-between mt-2 lg:mt-2 2xl:mt-4 text-sm lg:text-base 2xl:text-xl text-theme-black'>
                                <h4 className='font-bold'>Date of Birth</h4>
                                <p>{verifiedData.dob ? `${verifiedData.dob}` : '4th October, 2000'}</p>
                            </div>
                            <div className='flex justify-between mt-2 lg:mt-2 2xl:mt-4 text-sm lg:text-base 2xl:text-xl text-theme-black'>
                                <h4 className='font-bold'>Marital Status</h4>
                                <p>{verifiedData.isMarried === null ? 'Single' : 'Married'}</p>
                            </div>
                            {verifiedData.isMarried === null ? '' : (
                                <div className='flex justify-between mt-2 lg:mt-2 2xl:mt-4 text-sm lg:text-base 2xl:text-xl text-theme-black'>
                                    <h4 className='font-bold'>Number of Children</h4>
                                    <p>{verifiedData.numberOfChildren ? `${verifiedData.numberOfChildren}` : '4'}</p>
                                </div>
                            )}
                            <div className='flex justify-between mt-2 lg:mt-2 2xl:mt-4 text-sm lg:text-base 2xl:text-xl text-theme-black'>
                                <h4 className='font-bold'>Nature of Job</h4>
                                <p>{verifiedData.jobOption ? `${verifiedData.jobOption}` : 'Doctor'}</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Desktop-specific styles */}
            <style jsx>{`
                @media (min-width: 1024px) {
                    .desktop-margin-left {
                        margin-left: 2rem; /* Adds horizontal space */
                    }
                }
            `}</style>
        </>
    );
}
