import React from 'react';
import { Link } from 'react-router-dom';

export default function AboutUsSection() {
    return (
        <>
            <div className='px-[22px] py-[30px] lg:px-[108px] lg:py-[104px] flex justify-between lg:flex-row flex-col relative'>
                <div className='lg:w-[440px] xl:w-[600px] 2xl:w-[840px] space-y-3 xl:space-y-4 lg:space-y-2 2xl:space-y-6'>
                    <h2 className='font-bold text-theme-black tracking-wide xl:text-xl lg:text-lg 2xl:text-3xl pl-4'>About Us</h2>
                    <p className='text-[#230E0E] opacity-90 text-justify xl:text-base lg:text-sm 2xl:text-2xl pl-4 mb-6'>
                        We are a community dedicated to supporting Chitrali people living in Saudi Arabia. Our mission is to provide assistance in times of need, whether through financial support, medical aid, or emergency help. We aim to foster a sense of unity and belonging, ensuring that our members feel safe, valued, and connected.
                    </p>
                    <Link to="/about-us">
                        <button className='bg-theme-black hover:bg-white hover:text-black hover:border-black border duration-300 cursor-pointer text-white px-[26px] py-[10px] lg:px-[26px] lg:py-[8px] xl:px-[31px] xl:py-[13px] 2xl:px-[38px] 2xl:py-[17px] rounded-lg tracking-wide lg:text-base 2xl:text-xl pl-4 ml-4 mt-4 flex items-center space-x-2'>
                            <h6 className='m-0'>Learn More</h6> 
                            <span>→</span> {/* Unicode arrow */}
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}
