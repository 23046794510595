import React from 'react';

export default function JoinUsSection() {
    return (
        <>
            <div className='h-[209px] lg:h-[200px] xl:h-[200px] 2xl:h-[240px] bg-theme-black flex justify-center flex-col items-center'>
                <h2 className='text-white text-lg lg:text-xl xl:text-2xl 2xl:text-4xl font-bold'>JOIN US</h2>
                <p className='text-white px-[28px] lg:px-0 text-center lg:text-base xl:text-lg 2xl:text-2xl font-semibold mt-6 lg:mt-2 xl:mt-2 2xl:mt-4'>
                    Live a tension-free life in the Kingdom of Saudi Arabia
                </p>
            </div>
        </>
    );
}
